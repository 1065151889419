.blur-on-hover {
    filter: blur(0);
    transition: filter 0.6s ease-out; /* Add a smooth transition effect */
  }
  
  .blur-on-hover:hover {
    filter: blur(2px); /* Adjust the blur value as needed */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Add a subtle box shadow for depth */
  }

  .image-container {
    position: relative;
    display: inline-block;
  }

  .image-container:active .blur-on-hover {
    filter: blur(5px); /* Apply the blur effect when the image is clicked (active) */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
}
  
  .play-button {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2em;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease-out; /* Add a transition for opacity */
  }

  .blur-on-hover:hover + .play-button {
    opacity: 1; /* Make the play button visible on hover */
  }
  
  .image-container:active .play-button {
    opacity: 1; /* Make the play button visible when the image is clicked (active) */
  }

  .play-button:hover {
  opacity: 1; /* Add a transition for opacity */
  }