.navbar{
    width:100%;
    height:5rem;
    /* background-color: #1C1C1C; */
    background-color: #000000;

    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(160, 1, 1,0.58) 0px 20px 30px -10px;
    /* box-shadow: rgb(0, 0, 0) 0px 22px 70px 4px; */
}
.logo_container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
}
.image{
    width:100%;
    height: 100px;
    object-fit: cover;
}

.layout{
    margin-left: 5%;
    margin-right: 5%;
}