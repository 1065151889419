@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding:0;
  box-sizing: border-box;
}
a{
  text-decoration: none;
}

